import { ProductCardActionsModel } from "./ProductCardActions.componentModel";
import { PublicInterface } from "../../../../interface/common";
import { useElementContext } from "../../../../contexts";

export const ProductCardActionsView = ({
  componentData: { type, ...rest },
  setQuantity,
  onAddToCart,
}: {
  componentData: PublicInterface<ProductCardActionsModel>;
  setQuantity: (n: number) => void;
  onAddToCart: () => void;
}) => {
  const { ProductModule, ProductCardModule } = useElementContext();
  const { sku, isQtyFieldRequired, cardConfiguration, isFetching, itemCount } = rest;
  return (
    <div className="productCard-button-container py-3">
      {isQtyFieldRequired && (
        <>
          <ProductModule.CoreComponents.ProductOutOfStock isFetching={isFetching} sku={sku} />
          <div className="text-center border-0 bg-transparent pt-0 ">
            <div className="productCard_quantityInput">
              <ProductModule.CoreControl.ProductQuantityInput
                setQuantity={setQuantity}
                quantity={itemCount}
                sku={sku}
                showLabel={cardConfiguration?.showInputLabel}
                quantityInputType={cardConfiguration?.input?.quantityInput || "text"}
                showInventory={cardConfiguration?.input?.showInventory || false}
                dropdownLimitCount={cardConfiguration?.input?.dropdownLimitCount || 8}
              />
            </div>
          </div>
        </>
      )}
      <ProductCardModule.CoreControl.DropdownButtons {...rest} onAddToCart={onAddToCart} />
    </div>
  );
};
