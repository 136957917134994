import { useEffect, useMemo, useState } from "react";

import { Product } from "../../../../interface/Product";
import { RelatedProductsSliderModel, RelatedProductsSliderProps } from "./RelatedProductsSlider.componentModel";
import { SlatwalApiService } from "../../../../services";

export const useComponentData = (props: RelatedProductsSliderProps) => {
  const { productUrlTitle } = props;

  const [products, setProducts] = useState<Product[]>([]);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    let didCancel = false;
    setLoaded(false);
    SlatwalApiService.products.getRelatedProducts({ urlTitle: productUrlTitle }).then((response: any) => {
      if (didCancel) return;
      if (response.isSuccess()) {
        const products = response.success().relatedProducts.map((sku: any) => {
          return {
            ...sku,
            productName: sku.relatedProduct_productName,
            productCode: sku.relatedProduct_productCode,
            urlTitle: sku.relatedProduct_urlTitle,
            brandName: sku.relatedProduct_brand_brandName,
            brandUrlTitle: sku.relatedProduct_brand_urlTitle,
            imageFile: sku.relatedProduct_defaultSku_imageFile,
            skuID: sku.relatedProduct_defaultSku_skuID,
            skuCode: sku.relatedProduct_defaultSku_skuCode,
          };
        });
        setProducts(products);
      } else {
        setProducts([]);
      }
      setLoaded(true);
    });

    return () => {
      didCancel = true;
    };
  }, [productUrlTitle]);

  const componentData = useMemo(
    () => new RelatedProductsSliderModel({ props, products, isLoaded }),
    [isLoaded, products, props],
  );

  return { componentData };
};
