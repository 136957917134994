import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useFormatCurrency } from "../../../hooks";
import { getProductRoute } from "../../../selectors";

import { useElementContext } from "../../../contexts";
import { OrderItem } from "../../../interface/Cart";

export interface MiniCartProductItemProps {
  productItems: OrderItem[];
  productItem: OrderItem;
  cartAction: {
    removeItem?: (productItem: any) => void;
    getPrice?: (productItem: any) => any;
  };
  isBundleItem?: boolean;
}

export const MiniCartProductItem = ({
  productItems,
  productItem,
  cartAction,
  isBundleItem = false,
}: MiniCartProductItemProps) => {
  const {
    CommonModule: { SimpleImage },
  } = useElementContext();
  const { t } = useTranslation();
  const [formatCurrency] = useFormatCurrency({});
  const productRoute = useSelector(getProductRoute);
  const { sku, orderItemID, quantity } = productItem;
  const price = cartAction.getPrice?.(productItem);
  const { product, images } = sku;
  const { productName, urlTitle } = product;
  const childBundleItems = productItems?.filter((filteritem) => filteritem?.parentOrderItemID === orderItemID);

  return (
    <div className="d-flex align-items-center py-3 border-bottom border-light flex-wrap gap-2">
      {images?.length > 0 && (
        <SimpleImage className="img-fluid mw-50px productImage" src={images?.at(0)} alt={productName} type="product" />
      )}
      <Link to={`/${productRoute}/${urlTitle}`} className="cart-product-name">
        {productName}
      </Link>
      <span className="text-muted small fw-bolder">
        {quantity} x <span className="text-black">{formatCurrency(price)}</span>
      </span>
      {!isBundleItem && (
        <>
          <figure className="m-0">
            <i onClick={() => cartAction.removeItem?.(productItem)} className="bi bi-trash3" role="button"></i>
          </figure>
          {childBundleItems?.length > 0 && (
            <div className="accordion mt-3 w-100">
              <div className="accordion-item">
                <p className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button p-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse_${orderItemID}`}
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {t("frontend.cart.bundleProducts.child_items")}
                  </button>
                </p>
                <div
                  id={`collapse_${orderItemID}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                >
                  <div className="accordion-body">
                    {childBundleItems.map((childBundleItem) => (
                      <MiniCartProductItem
                        key={childBundleItem.orderItemID}
                        productItems={[]}
                        productItem={childBundleItem}
                        cartAction={cartAction}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
