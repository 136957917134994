import { AddProductToQuoteModalProps } from "./AddProductToQuoteModal.componentModel";
import { useComponentData } from "./AddProductToQuoteModal.componentService";
import { AddProductToQuoteModalView } from "./AddProductToQuoteModal.componentView";

const AddProductToQuoteModal = (props: AddProductToQuoteModalProps) => {
  const {
    componentData,
    componentService: {
      setQuoteModal,
      handleQuoteRedirect,
      setExistingQuoteVal,
      addToExistingQuote,
      addToNewQuote,
      setQuoteName,
    },
  } = useComponentData(props);
  return (
    <AddProductToQuoteModalView
      componentData={componentData}
      setQuoteModal={setQuoteModal}
      setQuoteName={setQuoteName}
      handleQuoteRedirect={handleQuoteRedirect}
      addToExistingQuote={addToExistingQuote}
      addToNewQuote={addToNewQuote}
      setExistingQuoteVal={setExistingQuoteVal}
    />
  );
};

export { AddProductToQuoteModal, type AddProductToQuoteModalProps };
