import { useLocation } from "react-router-dom";
import { processQueryParameters } from "../../../utils";
import { SortOption } from "../../../interface/SearchProduct";
import { useMemo } from "react";

export interface ListingToolBarProps {
  sortBy: SortOption[];
  setSort: (v: string) => void;
  recordsCount: number;
  searchConfig: any;
}

const ListingToolBar = ({ sortBy, setSort, recordsCount, searchConfig }: ListingToolBarProps) => {
  const loc = useLocation();
  const qs = processQueryParameters(loc.search);
  const sort = qs.sort;

  const sortOptions = useMemo(() => {
    if (searchConfig.searchCustomise?.sorting) {
      return sortBy.filter(
        (option) => searchConfig.searchCustomise.sorting.sortingToShow?.split(",").includes(option.slug),
      );
    }
    return sortBy;
  }, [searchConfig.searchCustomise, sortBy]);
  const dropdownLabel: SortOption | undefined = sortOptions.find((data) => data.slug === sort);

  if (recordsCount < 1 || sortOptions.length < 1) return null;

  return (
    <div className="sort-options m-0 pb-md-4 pb-sm-2">
      <div className="text-right">
        {searchConfig && (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle text-white"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {dropdownLabel?.name || "Sort By"}
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
              {sortOptions.map(({ name, slug }) => {
                return (
                  <li
                    key={name}
                    onClick={() => {
                      setSort(slug);
                    }}
                    className={dropdownLabel?.name === name ? "dropdown-item active" : "dropdown-item"}
                  >
                    {name}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export { ListingToolBar };
