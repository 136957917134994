import { Product, Sku } from "../../interface/Product";
import queryString from "query-string";
import { dynamicAreaLayoutEngine } from "../../services/factory/Factory_Content_LayoutEngine.class";
import { ProductImage } from "../../interface/ProductImageGallery";
import { Dispatch, SetStateAction } from "react";

import { ProductModule } from "../../modules/Product/product.moduleInterface";
import { DisplayConfig } from "../../modules/Product/product.moduleModels";
import { ModuleProps } from "../../interface/common";
import { IProductContext } from "../../contexts/ProductContext";

export interface PdpConfig {
  enableFavorites: boolean;
  showRelatedProducts: boolean;
  showInputLabel: boolean;
  showExistingQuoteAndLists: boolean;
  input: {
    showInventory: boolean;
    quantityInput: "text" | "dropdown";
  };
  options: {
    style: "dropdown" | "tile";
  };
  price: {
    showCriteria: "Always" | "Authenticated Users" | "Never";
  };
  defaultSkuOnPageLoad: false;
  breadcrumbsType: "Product type" | "Category";
  skuLabel: string;
}

interface Helmet {
  title: string;
}

type Crumb = {
  title: string;
  urlTitle: string;
};

class PdpPageModel {
  clientCustom: any;
  isFetching: boolean = false;
  product!: Product;
  productName?: string;
  productDescription?: string;
  selectedSKu?: Sku;
  helmet?: Helmet;
  showLoginForPrice: boolean = false;
  additionalInformation?: ModuleProps<typeof ProductModule.CoreControl.ProductAdditionalInformation>;
  productPrice?: ModuleProps<typeof ProductModule.CoreComponents.ProductPrice>;
  productDetailHeading?: ModuleProps<typeof ProductModule.CoreComponents.ProductDetailHeading>;
  productDetailGallery?: ModuleProps<typeof ProductModule.CoreComponents.ProductDetailGallery>;
  favoritesButton?: ModuleProps<typeof ProductModule.CoreComponents.HeartButton>;
  skuSelector?: ModuleProps<typeof ProductModule.CoreComponents.SkuSelector>;
  skuOptions?: ModuleProps<typeof ProductModule.CoreComponents.SkuOptions>;
  productForm?: ModuleProps<typeof ProductModule.CoreComponents.ProductForm>;
  productAttributes?: ModuleProps<typeof ProductModule.CoreComponents.ProductAttributes>;
  productBundle?: ModuleProps<typeof ProductModule.CoreComponents.ProductBundle>;
  relatedProductsSlider?: ModuleProps<typeof ProductModule.CoreComponents.RelatedProductsSlider>;
  productModifiers?: ModuleProps<typeof ProductModule.CoreControl.ProductModifiers>;

  crumbs: Crumb[] = [];
  dynamicContentElements: any[] = [];

  constructor({
    isCmsPageLoading,
    productContext,
    productTypeBase,
    productTypeRoute,
    categoryRoute,
    dynamicContentElements,
    displayConfig,
    params,
    isAuthenticated,
    sitePurpose,
    selection,
    setSelection,
    skuConfiguration,
    setSkuConfiguration,
  }: {
    isCmsPageLoading: boolean;
    productContext: IProductContext;
    productTypeBase: string;
    productTypeRoute: string;
    categoryRoute: string;
    dynamicContentElements: any[];
    displayConfig: DisplayConfig;
    params: queryString.ParsedQuery<string>;
    isAuthenticated: boolean;
    sitePurpose: string;
    selection: { [optionGroupCode: string]: string };
    setSelection: Dispatch<SetStateAction<{ [optionGroupCode: string]: string }>>;
    skuConfiguration: { [key: string]: string };
    setSkuConfiguration: Dispatch<SetStateAction<{ [key: string]: string }>>;
  }) {
    const pageLayoutEngine = new dynamicAreaLayoutEngine();

    const { product } = productContext;

    this.product = productContext.product;

    this.productName = product.productName;
    this.productDescription = product.productDescription;
    this.helmet = { title: product.settings.productHTMLTitleString };
    this.additionalInformation = product.additionalInformation
      ? { additionalInformation: product.additionalInformation }
      : undefined;
    this.isFetching = productContext.isFetching || isCmsPageLoading;

    this.dynamicContentElements = pageLayoutEngine.processPageComponents(dynamicContentElements);
    this.productAttributes = {
      product: productContext.product,
      attributeSets: productContext.attributeSets,
    };
    this.productBundle = {
      productBundle: productContext.productBundle,
      productBundleBuildOnAccount: productContext.productBundleBuildOnAccount,
      productID: productContext.product.productID,
    };
    this.relatedProductsSlider = displayConfig.showRelatedProducts
      ? { productUrlTitle: productContext.product.urlTitle }
      : undefined;

    const { skus, optionGroups } = product;

    this.setSelectedSku(product, selection, params);
    this.setCrumbs({
      product: productContext.product,
      productTypeRoute,
      productTypeBase,
      categoryRoute,
      breadcrumbsType: displayConfig.breadcrumbsType,
    });

    this.setShowLoginForPrice({
      isAuthenticated,
      priceShowCriteria: displayConfig.priceShowCriteria,
    });
    this.setProductPrice({
      isAuthenticated,
      sku: this.selectedSKu,
      priceShowCriteria: displayConfig.priceShowCriteria,
    });
    this.setProductDetailHeading({ product, sku: this.selectedSKu, displayConfig });
    this.setFavoritesButton({
      sku: this.selectedSKu,
      enableFavorites: displayConfig.showFavoritesButton,
    });
    this.setProductDetailGallery({
      sku: this.selectedSKu,
      imageGallery: productContext.imageGallery,
    });
    this.skuOptions = {
      selection,
      setSelection,
      product,
      config: displayConfig,
    };
    this.setSkuSelector({
      skus,
      sku: this.selectedSKu,
      productOptions: optionGroups,
    });
    this.setProductModeifiers({ modifiers: product.modifiers, skuConfiguration, setSkuConfiguration });
    this.setProductForm({
      sku: this.selectedSKu,
      sitePurpose,
      displayConfig,
      skuConfiguration,
      modifiers: product.modifiers,
    });
  }

  private setSelectedSku = (
    product: Product,
    selection: { [optionGroupCode: string]: string },
    params: queryString.ParsedQuery<string>,
  ) => {
    const optionGroupPairs = Object.entries(selection).map(([key, value]) => `${key}=${value}`);
    const { skus, optionGroups } = product;
    this.selectedSKu = skus.find((sku) => {
      return (
        optionGroupPairs.filter((code) => {
          return sku?.slug.includes(code);
        }).length === optionGroups.length
      );
    });

    //check if product is of gift card type, if yes then return default sku from sku list (as it will not have options)
    if (
      product?.productType_productTypeIDPath &&
      product?.defaultSku_skuID &&
      product.productType_productTypeIDPath.includes("50cdfabbc57f7d103538d9e0e37f61e4")
    ) {
      this.selectedSKu = skus.find((sku) => sku.skuID === product.defaultSku_skuID);
    }

    if (optionGroups?.length === 0 && skus.length > 0) {
      console.log("This is a product with skus without option groups");
      if (params?.skuid) {
        this.selectedSKu = skus?.find((sku) => sku.skuID === params?.skuid);
      }
      if (!this.selectedSKu) {
        this.selectedSKu = skus?.find((sku) => sku.skuID === product.defaultSku_skuID) || skus[0];
      }
    }
  };

  private setCrumbs = (data: {
    product: Product;
    productTypeRoute: string;
    productTypeBase: string;
    categoryRoute: string;
    breadcrumbsType: DisplayConfig["breadcrumbsType"];
  }) => {
    const { product, productTypeBase, productTypeRoute, categoryRoute, breadcrumbsType } = data;
    const { breadcrumbs, categories } = product;

    if (breadcrumbsType === "Category") {
      this.crumbs = categories.map((category) => ({
        title: category.categoryName,
        urlTitle: `/${categoryRoute}/${category.urlTitle}`,
      }));
    } else {
      this.crumbs = (breadcrumbs || [])
        ?.map((crumb) => {
          return {
            title: crumb.productTypeName,
            urlTitle: `/${productTypeRoute}/${crumb.urlTitle}`,
          };
        })
        .filter((crumb) => crumb.urlTitle !== `/${productTypeRoute}/${productTypeBase}`);
    }
  };

  private setShowLoginForPrice = ({
    priceShowCriteria,
    isAuthenticated,
  }: {
    priceShowCriteria: DisplayConfig["priceShowCriteria"];
    isAuthenticated: boolean;
  }) => {
    this.showLoginForPrice = !isAuthenticated && priceShowCriteria === "Authenticated Users";
  };

  private setProductPrice = ({
    sku,
    isAuthenticated,
    priceShowCriteria,
  }: {
    sku?: Sku;
    isAuthenticated: boolean;
    priceShowCriteria: DisplayConfig["priceShowCriteria"];
  }) => {
    if (!sku) return;
    if (priceShowCriteria === "Never") return;
    if (priceShowCriteria === "Authenticated Users" && !isAuthenticated) return;

    this.productPrice = {
      listPrice: sku.listPrice,
      salePrice: sku.salePrice,
      showPriceForUserType: sku.settings.skuShowPriceForUserType,
    };
  };

  private setProductDetailHeading = ({
    product,
    sku,
    displayConfig,
  }: {
    product: Product;
    sku?: Sku;
    displayConfig: DisplayConfig;
  }) => {
    this.productDetailHeading = { product, sku, skuLabel: displayConfig.skuLabel };
  };

  private setFavoritesButton = ({ sku, enableFavorites }: { sku?: Sku; enableFavorites: boolean }) => {
    this.favoritesButton = sku && enableFavorites ? { skuID: sku.sku_skuID } : undefined;
  };

  private setProductDetailGallery = ({ sku, imageGallery }: { sku?: Sku; imageGallery?: ProductImage[] }) => {
    this.productDetailGallery = imageGallery && {
      skuID: sku?.skuID,
      imageGallery,
    };
  };

  private setSkuSelector = ({ sku, skus, productOptions }: PdpPageModel["skuSelector"] & { productOptions: any[] }) => {
    if (productOptions?.length !== 0 || skus.length === 0) return;
    this.skuSelector = {
      sku,
      skus,
    };
  };

  private setProductModeifiers = ({
    modifiers,
    setSkuConfiguration,
    skuConfiguration,
  }: {
    modifiers: Product["modifiers"];
    skuConfiguration: { [key: string]: string };
    setSkuConfiguration: Dispatch<SetStateAction<{ [key: string]: string }>>;
  }) => {
    if (!modifiers?.length) return;
    this.productModifiers = { modifiers, setSkuConfiguration, skuConfiguration };
  };

  private setProductForm = ({
    sitePurpose,
    sku,
    displayConfig,
    modifiers,
    skuConfiguration,
  }: {
    sku: Sku | undefined;
    sitePurpose: string;
    displayConfig: DisplayConfig;
    modifiers: Product["modifiers"];
    skuConfiguration: { [key: string]: string };
  }) => {
    if (sitePurpose === "Non-Transactional") return;
    if (!sku) return;
    if (modifiers?.some((modifier) => modifier.required && !skuConfiguration[modifier.key])) return;

    this.productForm = {
      sku,
      showInputLabel: displayConfig?.showInputLabel,
      quantityInputMode: displayConfig.quantityInputMode,
      showInventory: displayConfig.showInventory,
      showExistingQuoteAndLists: displayConfig.showExistingQuoteAndLists,
      skuConfiguration: Object.keys(skuConfiguration).length ? skuConfiguration : undefined,
    };
  };
}

export default PdpPageModel;
