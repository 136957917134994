import { useTranslation } from "react-i18next";
import { Sku } from "../../../interface/Product";
import { Quote } from "../../../interface/Quote";
import { List } from "../../../interface/List";
import { useElementContext } from "../../../contexts";
import { addSkuToWishList } from "../../../actions";
import { useDispatch } from "react-redux";

const CART_ID = "cart-collapseOne";

export interface ProductMultiCartButtonsProps {
  sku: Sku;
  quotes: Quote[];
  wishlists: List;
  isLoading: boolean;
  setQuoteModal: (isOpen: boolean) => void;
  setListModal: (isOpen: boolean) => void;
  addToCartOrQuote: (quote?: Quote) => void;
  showExistingQuoteAndLists: boolean;
}

const ProductMultiCartButtons = ({
  sku,
  quotes,
  wishlists,
  showExistingQuoteAndLists,
  isLoading,
  setQuoteModal,
  addToCartOrQuote,
  setListModal,
}: ProductMultiCartButtonsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  console.log(wishlists);
  const getWishlistItemsTotalCount = (wishlistId: any) => {
    let wishlistItems = wishlists.items.filter((item: any) => item.orderTemplate_orderTemplateID === wishlistId);
    return wishlistItems.length;
  };

  const {
    CommonModule: { Button },
  } = useElementContext();
  return (
    <div className="accordion accordion-cart" id="accordioncartExample">
      <div className="accordion-item quote-input-box d-flex">
        {/* Add to cart button */}
        <Button
          classList="btn btn-primary flex-grow-1"
          isLoading={isLoading}
          disabled={!sku.settings.skuAllowAddToCartFlag || (!sku?.salePrice && !sku?.listPrice)}
          onClick={(e) => {
            e.preventDefault();
            addToCartOrQuote();
          }}
        >
          {t("frontend.product.add_to_cart")}
        </Button>

        {/* Add to quote dropdown */}
        <button
          disabled={!sku?.settings.skuAllowQuoteFlag}
          className="accordion-button addToQuote_dropdownBtn collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${CART_ID}`}
          aria-expanded="false"
          aria-controls={CART_ID}
        >
          <span className="pe-2">
            MORE
            <br />
            OPTIONS
          </span>
        </button>

        <div
          id={CART_ID}
          className="accordion-collapse accordion-absolute collapse"
          aria-labelledby="cart-headingOne"
          data-bs-parent="#accordioncartExample"
        >
          <div className="accordion-body">
            <div className="d-flex">
              <span className="text-primary addToQuote" onClick={() => setQuoteModal(true)} role="button">
                {t("frontend.product.add_to_quote")}
              </span>
            </div>
            {showExistingQuoteAndLists &&
              quotes.length > 0 && <span>Add To Existing Quote</span> &&
              quotes.map((quote) => (
                <div className="d-flex col-12 my-2 ps-2 justify-content-between" key={quote?.orderID}>
                  <b
                    role="button"
                    className={
                      "col-9 multiCartButton_moreOptions_option" +
                      (isLoading ? " multiCartButton_moreOptions_option-disabled" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      addToCartOrQuote(quote);
                    }}
                  >
                    {quote.orderTypeCode === "otSalesOrder" ? "Shopping cart" : quote?.quoteName}
                  </b>

                  <span className="col-3">
                    {quote?.totalItems > 1 ? `${quote?.totalItems} Items` : `${quote?.totalItems || 0} Item`}
                  </span>
                </div>
              ))}
            <div className="d-flex">
              <span className="text-primary addToList" onClick={() => setListModal(true)} role="button">
                {t("frontend.product.add_to_list")}
              </span>
            </div>
            {showExistingQuoteAndLists &&
              wishlists?.lists.length > 0 && <span>Add To Existing List</span> &&
              wishlists?.lists.map((list: any) => (
                <div className="d-flex col-12 my-2 ps-2 justify-content-between" key={list?.value}>
                  <b
                    role="button"
                    className={
                      "col-9 multiCartButton_moreOptions_option" +
                      (isLoading ? " multiCartButton_moreOptions_option-disabled" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(addSkuToWishList(sku.skuID, list.value) as any);
                    }}
                  >
                    {list.name !== " " ? list.name : "Default"}
                  </b>

                  <span className="col-3">
                    {getWishlistItemsTotalCount(list.value) > 2
                      ? `${getWishlistItemsTotalCount(list.value)} Items`
                      : `${getWishlistItemsTotalCount(list.value) || 0} Item`}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProductMultiCartButtons };
