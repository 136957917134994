import { Product } from "../../../../interface/Product";

export interface RelatedProductsSliderProps {
  productUrlTitle: string;
  slidesToShow?: number;
}

export class RelatedProductsSliderModel {
  slidesToShow: number = 4;
  isLoaded: boolean = false;
  products: Product[] = [];
  constructor({
    props,
    isLoaded,
    products,
  }: {
    props: RelatedProductsSliderProps;
    isLoaded: boolean;
    products: Product[];
  }) {
    this.slidesToShow = props.slidesToShow || 4;
    this.isLoaded = isLoaded;
    this.products = products;
  }
}
