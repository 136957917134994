import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../../../hooks";
import { getProductRoute } from "../../../../selectors";

import { CartLineItemModel } from "./CartLineItem.componentModel";
import { useElementContext } from "../../../../contexts";

const CartLineItemView = ({
  componentData: { childBundleItems, orderItem, isDisabled, isRemovingItem, itemCount },
  setItemCount,
  onUpdateQty,
  onRemoveItem,
}: {
  componentData: CartLineItemModel;
  onRemoveItem: () => void;
  onUpdateQty: () => void;
  setItemCount: (count: number) => void;
}) => {
  const {
    ProductModule,
    CommonModule: { SimpleImage },
  } = useElementContext();
  const [formatCurrency] = useFormatCurrency({});
  const { t } = useTranslation();
  const productRouting = useSelector(getProductRoute);

  return (
    <div className="card mb-4 border-0 p-3">
      {orderItem.errorMsg && (
        <div>
          <p className="text-danger">{orderItem.errorMsg}</p>
        </div>
      )}
      <div className="row detail">
        <div className="col-lg-3 image pb-md-0 pb-sm-4">
          {orderItem.sku.images && orderItem.sku.images?.length > 0 && (
            <SimpleImage
              className="img-fluid m-auto image_container productImage"
              src={orderItem.sku.images?.at(0)}
              alt={orderItem.sku.product.productName}
              type="product"
            />
          )}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
          <div className="title">
            <h4>
              <Link
                to={{
                  pathname: `/${productRouting}/${orderItem.sku.product.urlTitle}`,
                  // @ts-ignore
                  state: { ...orderItem.sku.product },
                }}
                className="text-decoration-none text-dark"
              >
                {orderItem.sku.product.productName}
              </Link>
            </h4>
          </div>
          <div className="font-size-sm item-sku">
            <span className="mr-2">
              {t("frontend.product.sku")} {orderItem.sku.skuCode}
            </span>
          </div>

          <div className="col-12 bottom-detail-box pt-3">
            <div className="row">
              <div className="item-price col-3">
                <ProductModule.CoreComponents.ProductPrice
                  type="cart"
                  salePrice={orderItem.extendedUnitPriceAfterDiscount}
                  listPrice={orderItem.sku.listPrice}
                />
              </div>
              {!isDisabled ? (
                <div className="number-range col-4">
                  <input
                    type="number"
                    className=""
                    value={itemCount}
                    disabled={isRemovingItem && !!orderItem.sku.skuID}
                    onChange={(e) => {
                      setItemCount(parseInt(e.target.value, 10));
                    }}
                  />
                  <button className="btn text-muted btn-link p-1 text-end" onClick={onUpdateQty}>
                    {t("frontend.account.cart.item.updateQuantity")}
                  </button>
                </div>
              ) : (
                <div className="col-4">
                  <small>{t("frontend.cart.quantity")}</small> {orderItem.quantity}
                </div>
              )}
              <div className="col-3">
                <h5 className="total-price">{formatCurrency(orderItem.extendedPriceAfterDiscount)}</h5>
              </div>
              <div className="item-delete-btn col-2">
                <span
                  className={`bi bi-trash3 ${isRemovingItem ? "pe-none" : "clickable"}`}
                  onClick={onRemoveItem}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {childBundleItems?.map((childBundleItem, key) => {
          return (
            <div className="col-3 d-flex" key={childBundleItem.orderItemID}>
              {key !== 0 ? (
                <i className="bi bi-plus-circle col-2 align-self-center"></i>
              ) : (
                <div className="col-2"></div>
              )}
              <Link className="col-10" to={`/${productRouting}/${childBundleItem.sku.product.urlTitle}`}>
                <SimpleImage
                  className="img-fluid  m-auto image_container productImage border border-light"
                  src={childBundleItem.sku.images?.at(0)}
                  alt={childBundleItem?.sku?.product?.productName}
                  type="product"
                />
                <span className="text-dark">
                  {` ${formatCurrency(childBundleItem.price)} x ${childBundleItem.quantity}`}
                </span>
                <p>{childBundleItem?.sku?.product?.productName}</p>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export { CartLineItemView };
