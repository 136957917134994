import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../../../hooks";
import { Link } from "react-router-dom";

import { ProductPriceModel } from "./ProductPrice.componentModel";

const ProductPriceView = ({
  componentData: { showPriceType, salePrice, listPrice, noPriceText, pricingPostText, pricingPreText },
}: {
  componentData: ProductPriceModel;
}) => {
  const [formatCurrency] = useFormatCurrency({});

  const { t } = useTranslation();

  if (showPriceType === "showLoginToViewPrice")
    return (
      <div className="alert alert-warning fs-6 productPrice_warning--loginToView" role="alert">
        {t("frontend.product.loginToView")}
        <br />
        <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
      </div>
    );

  if (showPriceType === "showVerifyForPricing")
    return (
      <div className="alert alert-warning fs-6 productPrice_warning--verifyForPrice" role="alert">
        {t("frontend.product.verifyForPricing")}
      </div>
    );

  if (showPriceType === "showMissingPrice")
    return (
      <div className="productPrice">
        <strong>{noPriceText}</strong>
      </div>
    );

  return (
    <div className="productPrice">
      <div className="productPrice_listingWrapper">
        <span className="productPrice_preText">{pricingPreText} </span>
        <span className="productPrice_price">
          {/*show list price only when list price is more than sale price */}
          {salePrice < listPrice ? (
            <>
              <s className="productPrice_listPrice">{`${formatCurrency(listPrice)}`}</s>
              <span className="text-primary productPrice_salePrice">{formatCurrency(salePrice)}</span>
            </>
          ) : (
            <span className="text-primary productPrice_listPrice">{`${formatCurrency(listPrice)}`}</span>
          )}
        </span>
        <span className="productPrice_postText">{pricingPostText}</span>
      </div>
    </div>
  );
};

export { ProductPriceView };
