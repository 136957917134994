import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import { useElementContext } from "../../../contexts";
import { axios, getSdkURL } from "../../../services";
import { getWishlists, getWishlistsItems } from "../../../selectors";
import { getWishLists, receiveUser } from "../../../actions";
import { useTranslation } from "react-i18next";

const AccountLists = () => {
  const {
    CommonModule: { AccountLayout, AccountContent },
  } = useElementContext();
  const wishLists = useSelector(getWishlists);
  const wishListItems = useSelector(getWishlistsItems);
  const { t } = useTranslation();
  const MySwal = withReactContent(Swal);
  const [selectedWishlist, setSelectedWishlist] = useState([]);
  const dispatch = useDispatch();

  const getWishlistItemsfromWishlistID = () => {
    const orderTemplateItemIDs = [];
    selectedWishlist &&
      selectedWishlist.forEach((wishListID) => {
        const filteredList = wishListItems.filter((item) => wishListID === item.orderTemplate_orderTemplateID);
        filteredList.forEach((wishListItem) => {
          orderTemplateItemIDs.push(wishListItem.orderTemplateItemID);
        });
      });
    return orderTemplateItemIDs.toString();
  };

  const removeSingleList = (orderTemplateID) => {
    MySwal.fire({
      icon: "info",
      title: <p>Are you sure, You want to delete the list</p>,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes, Delete it!",
    }).then((data) => {
      if (data.isConfirmed) {
        axios({
          method: "POST",
          withCredentials: true,
          url: `${getSdkURL()}api/scope/deleteOrderTemplate`,
          data: { orderTemplateID },
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (response?.status === 200 && response?.data?.failureActions.length === 0) {
            dispatch(getWishLists(true));
            toast.success("List deleted successfully!");
          } else {
            toast.success("Error Found!");
          }
        });
      }
    });
  };

  const removeList = () => {
    const orderTemplateItemIDs = getWishlistItemsfromWishlistID();
    if (orderTemplateItemIDs.length > 0) {
      MySwal.fire({
        icon: "info",
        title: <p>Are you sure, You want to delete the list</p>,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Yes, Delete it!",
      }).then((data) => {
        if (data.isConfirmed) {
          axios({
            method: "POST",
            withCredentials: true,
            url: `${getSdkURL()}api/scope/deleteOrderTemplateItems`,
            data: { orderTemplateItemIDs },
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            if (response?.status === 200 && response?.data?.failureActions.length === 0) {
              removeLists();
            } else {
              toast.success("Error Found!");
            }
          });
        }
      });
    } else {
      removeLists();
    }
  };

  const removeLists = () => {
    axios({
      method: "POST",
      withCredentials: true,
      url: `${getSdkURL()}api/scope/deleteOrderTemplates`,
      data: {
        orderTemplateIDs: selectedWishlist.toString(),
        returnJSONObjects: "account",
      },
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      dispatch(getWishLists(true));
      dispatch(receiveUser(response.data.account));
      setSelectedWishlist([]);
      toast.success("List deleted successfully!");
    });
  };

  const changeEvent = (e) => {
    if (e?.target?.name === "select_all_todo") {
      if (e?.target?.checked === true)
        setSelectedWishlist(
          wishLists.map((wishList) => {
            return wishList.value;
          }),
        );
      else setSelectedWishlist([]);
    } else {
      if (e?.target?.checked) {
        setSelectedWishlist((wishList) => Array.from(new Set([...wishList, e.target.value])));
      } else {
        const todos = selectedWishlist.filter((wishList) => wishList !== e.target.value);
        setSelectedWishlist(todos);
      }
    }
  };

  return (
    <AccountLayout>
      <AccountContent />
      <h2>
        <i className="bi bi-folder"></i> {t("frontend.listfolders.heading")}
      </h2>
      {wishLists.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <input className="ms-2" type={"checkbox"} onChange={(e) => changeEvent(e)} name={"select_all_todo"} />
              <div className="ms-3">{t("frontend.listfolders.listName")}</div>
            </div>

            <div>
              <div className="accordion accordion-cart" id="accordioncartExample">
                <div className="accordion-item quote-input-box d-flex">
                  <button
                    disabled={selectedWishlist === 0 ? true : false}
                    className="p-2 rounded-0 accordion-button roudned-0 flex-grow-1 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${"listItem_accordion"}`}
                    aria-expanded="false"
                    aria-controls={"listItem_accordion"}
                  >
                    <span className="p-1">{t("frontend.listfolders.bulkActions")}</span>
                  </button>

                  <div
                    id={"listItem_accordion"}
                    className="accordion-collapse accordion-absolute collapse"
                    aria-labelledby="cart-headingOne"
                    data-bs-parent="#accordioncartExample"
                  >
                    <div className="accordion-body p-3">
                      <div className="row">
                        <button className="p-2 bg-transparent border-0" onClick={() => removeList()}>
                          {t("frontend.listfolders.removeSelected")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <table className="table my-4">
            <tbody>
              {wishLists.map((item, index) => {
                return (
                  <tr key={item.value}>
                    <td width="3%">
                      <input
                        type={"checkbox"}
                        value={item.value}
                        checked={selectedWishlist.includes(item.value)}
                        onChange={changeEvent}
                        name={`list_${index}`}
                      />
                    </td>
                    <td> {item.name.trim() || "Default"} </td>
                    <td className="text-end">
                      <Link to={"/my-account/list/" + item.value}>
                        <i className="bi bi-eye-fill"></i>
                      </Link>
                      <span className="mx-2 btn-link link" role="button" onClick={() => removeSingleList(item.value)}>
                        <i className="bi bi-trash"></i>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <span>
              {selectedWishlist.length === 0 && <i>Please select item(s)</i>}
              {selectedWishlist.length > 0 && (
                <i>
                  Selected {selectedWishlist.length} of {wishLists.length} items
                </i>
              )}
            </span>
          </div>
        </>
      ) : (
        <h4> {t("frontend.listfolders.noresults")}</h4>
      )}
    </AccountLayout>
  );
};

export { AccountLists };
