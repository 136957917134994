import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Product } from "../../../../interface/Product";
import { RelatedProductsSliderModel } from "./RelatedProductsSlider.componentModel";
import { useElementContext } from "../../../../contexts/ElementContext";
import { SearchProduct } from "../../../../interface/SearchProduct";

const RelatedProductsSliderView = ({ componentData }: { componentData: RelatedProductsSliderModel }) => {
  const { t } = useTranslation();
  const { ProductCardModule } = useElementContext();
  const { slidesToShow, products, isLoaded } = componentData;

  if (!products.length) {
    return null;
  }
  const settings = {
    dots: false,
    infinite: products && products.length >= slidesToShow,
    // infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="content-spacer">
      <div className="container">
        <header className="section-title mb-5 pb-2">
          <h2 className="mb-5">{t("frontend.product.related")}</h2>
        </header>
        <div className="card border-0 bg-transparent">
          <Slider {...settings}>
            {isLoaded &&
              products.map((product: Product) => {
                return (
                  <div className="repeater" key={product.productCode}>
                    <ProductCardModule.CoreControl.ProductCard
                      cardConfiguration={{
                        input: {
                          showCriteria: "none",
                          label: "",
                        },
                        showPrice: false,
                        showBrand: false,
                        showSkuCode: false,
                        showProductCode: true,
                        buttons: [
                          {
                            listingButtonLabel: "View More",
                            listingButtonDisplayCriteria: "all",
                            disableListingButton: false,
                            showAuthenticationRequiredMessageFlag: false,
                            hideListingButton: false,
                            type: "VIEW",
                          },
                        ],
                      }}
                      {...(product as unknown as SearchProduct)}
                    />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>
    </section>
  );
};
export { RelatedProductsSliderView };
