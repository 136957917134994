import { useTranslation } from "react-i18next";

import { AddProductToQuoteModalModel } from "./AddProductToQuoteModal.componentModel";
import { useElementContext } from "../../../../contexts";

export const AddProductToQuoteModalView = ({
  componentData: {
    skuID,
    skuIDs,
    isAuthenticated,
    isLoading,
    isLoadingForExisitingQuote,
    quoteName,
    existingQuoteVal,
    existingQuotes,
  },
  setQuoteModal,
  setQuoteName,
  handleQuoteRedirect,
  addToExistingQuote,
  addToNewQuote,
  setExistingQuoteVal,
}: {
  componentData: AddProductToQuoteModalModel;
  setQuoteModal: (isOpen: boolean) => void;
  setQuoteName: (name: string) => void;
  handleQuoteRedirect: () => void;
  addToExistingQuote: () => void;
  addToNewQuote: () => void;
  setExistingQuoteVal: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const {
    CommonModule: { Portal, SwSelect, Button, Modal },
  } = useElementContext();

  if (!isAuthenticated) {
    return (
      <Portal>
        <Modal show setShow={setQuoteModal} title={t("frontend.quote.addToQuote")} size="medium">
          <div className="container text-center">
            <div>
              <h4 className="p-0 fw-bold">{t("frontend.logintoperformaction")}</h4>
              <hr />
              <Button label={t("frontend.account.login")} onClick={handleQuoteRedirect} />
            </div>
          </div>
        </Modal>
      </Portal>
    );
  }

  return (
    <Portal>
      <Modal show setShow={setQuoteModal} title={t("frontend.quote.addToQuote")} size="medium">
        <div className="container">
          {existingQuotes.length > 0 && (skuID || skuIDs) && (
            <>
              <form name="add-to-exisiting-quote">
                <div className="row text-align-center">
                  <div className="col-md-12 justify-content-center">
                    <div className="form-group py-3">
                      <label htmlFor="existingQuote">{t("frontend.quote.existingQuote")}</label>
                      <SwSelect
                        id="existingQuote"
                        value={existingQuoteVal}
                        onChange={(e: any) => {
                          setExistingQuoteVal(e.target.value);
                        }}
                        options={existingQuotes.length > 0 ? existingQuotes : []}
                      />
                      {!existingQuoteVal ? <span className="form-error-msg">{t("frontend.core.required")}</span> : null}

                      <Button
                        isLoading={isLoadingForExisitingQuote}
                        classList="btn btn-primary btn-block mt-4"
                        type="button"
                        onClick={addToExistingQuote}
                        disabled={isLoadingForExisitingQuote}
                      >
                        <span>{t("frontend.quote.addToExistingQuote")}</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </form>

              <hr
                className="hr-text"
                data-content={t("frontend.account.order.template.detail.toolbar.scheduleDateModal.orText")}
              />
            </>
          )}

          <form name="add-quote">
            <div className="row text-align-center">
              <div className="col-md-12 justify-content-center">
                <div className="form-group me-3">
                  <label htmlFor="quoteName">{t("frontend.quote.label")}</label>
                  <input
                    className="form-control"
                    type="text"
                    id="quoteName"
                    value={quoteName}
                    onChange={(e) => {
                      setQuoteName(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    type="submit"
                    classList="btn btn-primary btn-block mt-4 d-block m-auto"
                    onClick={addToNewQuote}
                  >
                    <span className="d-sm-inline">{t("frontend.quote.createButton")}</span>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </Portal>
  );
};
