export interface SearchBarProps {
  redirectToSearchPage?: boolean;
  searchBoxPlaceholder?: string;
  searchBoxTypeaheadFlag?: boolean;
  customStyleClasses?: string;
}

export const SUGGESTION_TYPE_MAPPING = {
  brand: "Brand",
  productType: "Product Type",
  category: "Category",
};

export const getSuggestionsByShowCount = (
  searched: any,
  suggestionShowCount: number,
  suggestionPreTypeCount: number,
  routeMapping: { [key: string]: string },
) => {
  return searched?.suggest?.reduce(
    (
      acc: { type: string; text: string; link: string; badge?: string }[],
      cur: { [key: string]: { options: any[] }[] },
    ) => {
      Object.keys(cur)?.forEach((key) => {
        let typeCount = 0;
        cur[key]?.forEach(({ options }) => {
          options.forEach((option) => {
            if (typeCount >= suggestionPreTypeCount || acc.length >= suggestionShowCount) return;
            if (["brand", "cateogry", "productType"].includes(key) && routeMapping[key.toLowerCase()]) {
              if (option[key] && option[`${key}UrlTitlePath`]) {
                acc.push({
                  type: key,
                  text: option[key],
                  link: `/${routeMapping[key.toLowerCase()]}/${option[`${key}UrlTitlePath`]}`,
                });
              } else if (option[key] && option[`${key}UrlTitle`]) {
                acc.push({
                  type: key,
                  text: option[key],
                  link: `/${routeMapping[key.toLowerCase()]}/${option[`${key}UrlTitle`]}`,
                });
              }
              typeCount++;
            }
            if (typeof option === "string") acc.push({ type: key, text: option, link: `/shop?keyword=${option}` });
          });
        });
      });
      return acc;
    },
    [],
  );
};

export class SearchBarModel {
  searched: any;
  opened: boolean = false;
  searchBoxPlaceholder: string = "";
  searchTerm: string;
  suggestions: { type: string; text: string; link: string; badge?: string }[] = [];
  suggestionShowCount: number = 10;
  suggestionPreTypeCount: number = 2;
  suggestionTypeMapping: { [key: string]: string } = SUGGESTION_TYPE_MAPPING;
  customStyleClasses?: string;

  constructor({
    opened,
    searchBoxPlaceholder,
    searchTerm,
    searched,
    routeMapping,
    customStyleClasses,
  }: Omit<
    SearchBarModel,
    "suggestions" | "suggestionPreTypeCount" | "suggestionShowCount" | "suggestionTypeMapping"
  > & { routeMapping: { [key: string]: string } }) {
    this.customStyleClasses = customStyleClasses;
    this.searched = searched;
    this.opened = opened;
    this.searchBoxPlaceholder = searchBoxPlaceholder || "";
    this.searchTerm = searchTerm;
    this.suggestions = getSuggestionsByShowCount(
      searched,
      this.suggestionShowCount,
      this.suggestionPreTypeCount,
      routeMapping,
    );
  }
}
