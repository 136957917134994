export interface AttributeListProps {
  isSelected: boolean;
  showCounts: boolean;
  facet: any;
  filterName: string;
  facetKey: string;
  updateAttribute: (data: { name: string; filterName: string }) => void;
}

export const AttributeList = ({
  isSelected,
  showCounts = true,
  facet,
  filterName,
  facetKey,
  updateAttribute,
}: AttributeListProps) => {
  const token = filterName.replace(/\s/g, "") + (facet.name || facet.slug).replace(/\s/g, "") + "input";
  const updateAction = () => {
    updateAttribute({ name: facet.slug, filterName: facetKey });
  };
  return (
    <div className="d-flex justify-content-between">
      <div className="form-check pb-0 pe-3">
        <input className="form-check-input" type="checkbox" checked={isSelected} onChange={updateAction} id={token} />
        <label className="form-check-label" onClick={updateAction}>
          {facet.name || facet.slug}
        </label>
      </div>
      <div className="pt-1">
        {showCounts && facet.count && (
          <span style={{ fontSize: 12 }} className="text-muted ml-3 text-right">
            {facet.count}
          </span>
        )}
      </div>
    </div>
  );
};
