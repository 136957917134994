import { AddProductToListModalProps } from "./AddProductToListModal.componentModel";
import { useComponentData } from "./AddProductToListModal.componentService";
import { AddProductToListModalView } from "./AddProductToListModal.componentView";

const AddProductToListModal = (props: AddProductToListModalProps) => {
  const { componentData, componentService } = useComponentData(props);

  return (
    <AddProductToListModalView
      componentData={componentData}
      setListModal={componentService.setListModal}
      setExistingListVal={componentService.setExistingListVal}
      setListName={componentService.setListName}
      handleListRedirect={componentService.handleListRedirect}
      onAddToList={componentService.onAddToList}
      onCreateList={componentService.onCreateList}
    />
  );
};

export { AddProductToListModal, type AddProductToListModalProps };
