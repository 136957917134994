import { useEffect } from "react";
import { useGetOrderDetails } from "../../../hooks";
import { useElementContext } from "../../../contexts";

const AccountOrderDetail = (props) => {
  const orderID = props.path;
  let [order, setRequest, formatOrderDetails] = useGetOrderDetails();
  const {
    CommonModule: { AccountLayout, OrderFulfilments, OrderToolbar, OrderDetails },
  } = useElementContext();

  useEffect(() => {
    let didCancel = false;
    if (!order.isFetching && !order.isLoaded && !didCancel) {
      setRequest({
        ...order,
        isFetching: true,
        isLoaded: false,
        params: { orderID },
        makeRequest: true,
      });
    }
    return () => {
      didCancel = true;
    };
  }, [order, orderID, setRequest]);
  let orderFulfillmentGroups = formatOrderDetails(order.data);

  return (
    <AccountLayout title={`Order: ${(order.isLoaded && order.data.orderInfo?.at(0).orderNumber) || ""}`}>
      {order.isLoaded && (
        <OrderToolbar delivered={order.data.orderInfo?.at(0)} orderPayments={order.data.orderPayments?.at(0)} />
      )}
      {order.isLoaded && (
        <OrderDetails
          orderInfo={order.data.orderInfo?.at(0)}
          orderFulfillments={orderFulfillmentGroups}
          orderPayments={order.data.orderPayments?.at(0)}
        />
      )}
      {order.isLoaded && <OrderFulfilments fulfilments={orderFulfillmentGroups} files={order.data?.files} />}
    </AccountLayout>
  );
};
export { AccountOrderDetail };
