import jwt_decode from "jwt-decode";
import queryString from "query-string";
import { toast } from "react-toastify";
import { LocalStorageService } from "./localStorage";
import "./window";
import { Productcategory } from "../interface/Product";

export const isExternalURL = (url: string) => {
  try {
    return new URL(url).origin !== window.location.origin;
  } catch {
    return false;
  }
};

export const cleanHTML = (data: string) => data.replace("class=", "className=");
export const renameKeys = (obj: { [key: string]: string }, find: string, replace = "") => {
  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(find, replace);
    obj[newKey] = obj[key];
    delete obj[key];
  });
};
export const renameKeysInArrayOfObjects = (arr: { [key: string]: string }[], find: string, replace: string) => {
  arr.forEach((obj) => {
    renameKeys(obj, find, replace);
  });
};

export const isAuthenticated = () => {
  let token = LocalStorageService.getItem("token");
  if (token) {
    try {
      const decodedToken = jwt_decode(token) as any;
      return decodedToken.exp && decodedToken.exp * 1000 > Date.now() && decodedToken.accountID.length > 0;
    } catch (error) {}
  }
  return false;
};
export const getMyAccountUrl = () => {
  return isAuthenticated() ? "/my-account/overview" : "/my-account/login";
};
export const isImpersonating = () => {
  let token = LocalStorageService.getItem("token");
  if (token) {
    try {
      const decodedToken = jwt_decode(token) as any;
      return decodedToken?.isImpersonating || false;
    } catch (error) {}
  }
  return false;
};
export const toBoolean = (val = false) => {
  if (isBoolean(val)) return val;
  if (Number.isInteger(val)) return (val as unknown as number) > 0;
  if (isString(val))
    return (
      (val as unknown as string)?.toLowerCase()?.trim() === "true" ||
      (val as unknown as string)?.toLowerCase()?.trim() === "yes" ||
      (val as unknown as string)?.toLowerCase()?.trim() === "1"
    );
  return false;
};
export const containsHTML = (str: string) => /<[a-z][\s\S]*>/i.test(str);
export const isString = (val: any) => "string" === typeof val;
export const isBoolean = (val: any) => "boolean" === typeof val;
export const booleanToString = (value: boolean) => (value ? "Yes" : "No");
export const skuIdsToSkuCodes = (idList: any, productOptionGroups: any) => {
  return productOptionGroups
    .map((optionGroup: any) =>
      optionGroup.options
        .filter((option: any) => {
          return idList.includes(option.optionID);
        })
        .map((option: any) => {
          let payload: any = {};
          payload[optionGroup.optionGroupCode] = option.optionCode;
          return payload;
        }),
    )
    .flat();
};
export const parseErrorMessages = (error: any): any => {
  if (error instanceof Object) {
    return Object.keys(error)
      .map((key: any) => parseErrorMessages(error[key]))
      .flat();
  }
  return error;
};
export const getErrorMessage = (error: any) => {
  return parseErrorMessages(error)?.join(". ");
};

export const getFailureMessageOnSuccess = (response: any, message: any) => {
  if (response.isSuccess() && Object.keys(response.success()?.messages || {}).length) return toast.error(message);
};

export const organizeProductTypes = (parents: any, list: any) => {
  return parents.map((parent: any) => {
    let childProductTypes = list
      .filter((productType: any) => {
        return (
          productType.productTypeIDPath.includes(parent.productTypeIDPath) &&
          productType.productTypeID !== parent.productTypeID
        );
      })
      .filter((productType: any) => {
        return productType.productTypeIDPath.split(",").length === parent.productTypeIDPath.split(",").length + 1;
      });
    if (list.length > 0) {
      childProductTypes = organizeProductTypes(childProductTypes, list);
    }
    return { ...parent, childProductTypes };
  });
};
export const augmentProductType = (parent: any, data: any) => {
  let parents = data.filter((productType: any) => {
    return productType.urlTitle.toLowerCase() === parent.toLowerCase();
  });
  parents = organizeProductTypes(parents, data);

  if (parents.length > 0) {
    parents = parents?.at(0);
  }
  return parents;
};

export const groupBy = (xs: any[], key: string) => {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
export const processQueryParameters = (params: string) => {
  let qParams = queryString.parse(params, {
    arrayFormat: "separator",
    arrayFormatSeparator: ",",
  });
  return qParams;
};
export const sorting = (array: any[], order: string, key: string) => {
  array.sort(function (a, b) {
    var A = a[key],
      B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });

  return array;
};
export const getOptionByCode = (filteredOptions: any, optionGroupCode: any, optionCode: any) => {
  return filteredOptions
    .filter((optionGroup: any) => optionGroupCode === optionGroup.optionGroupCode)
    .map((optionGroup: any) => optionGroup.options.filter((option: any) => optionCode === option.optionCode))
    .flat()
    .shift();
};
export const getContentByTypeCode = (content: any[] = [], code = "") => {
  return content
    .filter((con) => code.split(",").includes(con?.contentElementType_systemCode))
    .sort((a, b) => a.sortOrder - b.sortOrder);
};
export const getContentPages = (content: any[] = []) => {
  return content.filter((con) => con?.isPageFlag === true).sort((a, b) => a.sortOrder - b.sortOrder);
};
export const getAllChildrenContentByType = (content: any[] = [], code = "") => {
  const response: any[] = [];
  content.forEach((con) => {
    if (con?.contentElementType_systemCode === code) {
      response.push(con);
    }
    if (con.children.length) {
      const children = getAllChildrenContentByType(con.children, code);
      response.push(...children);
    }
  });
  response.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });
  return response;
};

export const deepMerge = (source: any, target: any) => {
  for (const [key, val] of Object.entries(source)) {
    if (val !== null && typeof val === `object`) {
      if (target[key] === undefined) {
        target[key] = new (val as any).__proto__.constructor();
      }
      deepMerge(val, target[key]);
    } else {
      target[key] = val;
    }
  }
  return target; // we're replacing in-situ, so this is more for chaining than anything else
};

export const unflattenObject = (obj: any, delimiter = "_") =>
  Object.keys(obj).reduce((res, k) => {
    k.split(delimiter).reduce(
      (acc: any, e, i, keys) =>
        acc[e] || (acc[e] = isNaN(Number(keys[i + 1])) ? (keys.length - 1 === i ? obj[k] : {}) : []),
      res,
    );
    return res;
  }, {});

export const formatXml = (xml: string, tab = "    ", nl = "\n") => {
  let formatted = "",
    indent = "";
  const nodes = xml.slice(1, -1).split(/>\s*</);
  if (nodes[0][0] === "?") formatted += "<" + nodes.shift() + ">" + nl;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    if (node[0] === "/") indent = indent.slice(tab.length); // decrease indent
    formatted += indent + "<" + node + ">" + nl;
    if (node[0] !== "/" && node[node.length - 1] !== "/" && node.indexOf("</") === -1) indent += tab; // increase indent
  }
  return formatted;
};

export const getCurrentSiteCode = () => {
  let localSiteCode = LocalStorageService.getItem("appConfiguration")?.currentSite;
  if (!!localSiteCode) return localSiteCode;
  const currentSite = window.UC?.integrations?.commerce.siteCode;
  LocalStorageService.setItem("appConfiguration", {
    currentSite: currentSite || "",
    sites: [],
  });
  return currentSite || "";
};

export const jsonParse = (jsonString: string | null) => {
  try {
    return JSON.parse(jsonString || "");
  } catch {
    return null;
  }
};

const sortProductType = (
  a: { urlTitle: string; productTypeName: string },
  b: { urlTitle: string; productTypeName: string },
  rangeMetaData: { [key: string]: { rangeName: string; sortOrder?: number } },
) => {
  const aSortOrder = rangeMetaData[a.urlTitle.split("/").pop() || ""]?.sortOrder ?? Infinity;
  const bSortOrder = rangeMetaData[b.urlTitle.split("/").pop() || ""]?.sortOrder ?? Infinity;
  if (aSortOrder > bSortOrder) return 1;
  if (aSortOrder < bSortOrder) return -1;
  return (rangeMetaData[a.urlTitle.split("/").pop() || ""]?.rangeName || a.productTypeName) >
    (rangeMetaData[b.urlTitle.split("/").pop() || ""]?.rangeName || b.productTypeName)
    ? 1
    : -1;
};

export const sortProductTypes = (
  productTypes: { urlTitle: string; productTypeName: string }[],
  rangeMetaData: { [key: string]: { rangeName: string; sortOrder?: number } },
) => {
  return productTypes.sort((a, b) => sortProductType(a, b, rangeMetaData));
};

const sortCategory = (
  a: { urlTitle: string; categoryName: string },
  b: { urlTitle: string; categoryName: string },
  categoryMetaData: { [key: string]: { categoryName?: string; sortOrder?: number } },
) => {
  const aSortOrder = categoryMetaData[a.urlTitle.split("/").pop() || ""]?.sortOrder ?? Infinity;
  const bSortOrder = categoryMetaData[b.urlTitle.split("/").pop() || ""]?.sortOrder ?? Infinity;
  if (aSortOrder > bSortOrder) return 1;
  if (aSortOrder < bSortOrder) return -1;
  return (categoryMetaData[a.urlTitle.split("/").pop() || ""]?.categoryName || a.categoryName) >
    (categoryMetaData[b.urlTitle.split("/").pop() || ""]?.categoryName || b.categoryName)
    ? 1
    : -1;
};

export const sortCategories = (
  categories: Productcategory[],
  categoryMetaData: { [key: string]: { categoryName?: string; sortOrder?: number } },
) => {
  return categories.sort((a, b) => sortCategory(a, b, categoryMetaData));
};

export { LocalStorageService };
