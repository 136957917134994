import { ProductModifier } from "../../interface/Product";

export interface SelectedSkuConfigurationProps {
  modifiers: ProductModifier[];
  skuConfiguration: { [key: string]: string };
}

const SelectedSkuConfiguration = ({ modifiers, skuConfiguration }: SelectedSkuConfigurationProps) => {
  return (
    <details>
      <summary>Configuration</summary>
      <div>
        {modifiers.map(({ title, key, options }) => {
          const option = options.find((option) => option.slug === skuConfiguration[key]);
          if (option)
            return (
              <div key={key}>
                <small>
                  {title}: {option.title}
                </small>
              </div>
            );
          return null;
        })}
      </div>
    </details>
  );
};

export { SelectedSkuConfiguration };
