import { useMemo, useState } from "react";
import { useElementContext } from "../../contexts";
import { Product, Sku } from "../../interface/Product";

export interface ProductModifiersModalProps {
  sku?: Sku;
  product: Product;
  setShow: (open: boolean) => void;
  addItem?: (skuConfiguration: { [key: string]: string }) => void;
}

const ProductModifiersModal = ({ sku, product, setShow, addItem }: ProductModifiersModalProps) => {
  const [skuConfiguration, setSkuConfiguration] = useState<{ [key: string]: string }>({});
  const showAddItem = useMemo(
    () => product.modifiers?.every((modifier) => !modifier.required || skuConfiguration[modifier.key]),
    [product.modifiers, skuConfiguration],
  );
  const {
    CommonModule: { Modal },
    ProductModule,
  } = useElementContext();
  return (
    <Modal setShow={setShow} title="Select product configuration">
      <h3 className="p-0">{sku?.skuName || sku?.productName || product.productName}</h3>
      <ProductModule.CoreControl.ProductModifiers
        modifiers={product.modifiers || []}
        setSkuConfiguration={setSkuConfiguration}
        skuConfiguration={skuConfiguration}
      />
      {showAddItem && (
        <button
          className="btn btn-primary"
          onClick={() => {
            addItem?.(skuConfiguration);
          }}
        >
          Add Item
        </button>
      )}
    </Modal>
  );
};

export { ProductModifiersModal };
