import Slider from "react-slick";
import { useState } from "react";
import { toast } from "react-toastify";
import isEqual from "fast-deep-equal";
import { axios, getSdkURL } from "../../../services";
import { getErrorMessage } from "../../../utils";
import { useElementContext } from "../../../contexts";

const LoaderWrapper = ({ loader, children }) => {
  return loader ? <div> Loading .... </div> : children;
};

const ProductFilterGroupList = ({ product, selectedSkus, setSelectedSkus }) => {
  const hasOptionGroupsOnProduct = product?.optionGroups?.length > 0;
  const [selectedOptionGroup, setSelectedOptionGroup] = useState([]);
  const [currentOptionGroup, setCurrentOptionGroup] = useState(hasOptionGroupsOnProduct ? product.optionGroups[0] : {});
  const [optionsToMap, setOptionsToMap] = useState(hasOptionGroupsOnProduct ? product.optionGroups[0].options : []);
  const [showSkuModifiers, setShowSkuModifiers] = useState(null);
  const [loader, setLoader] = useState(false);

  const {
    CommonModule: { ProductModifiersModal },
  } = useElementContext();

  const sliderSettings = {
    dots: false,
    slidesToShow: 2,
    variableWidth: true,
    infinite: false,
  };

  const resetCompStates = () => {
    setOptionsToMap(hasOptionGroupsOnProduct ? product.optionGroups[0].options : []);
    setSelectedOptionGroup([]);
    if (hasOptionGroupsOnProduct) {
      setCurrentOptionGroup({
        optionGroupCode: product.optionGroups[0].optionGroupCode,
        optionGroupName: product.optionGroups[0].optionGroupName,
      });
    } else {
      setCurrentOptionGroup({});
    }
  };

  const getFilteredOptionGroups = (selectedOptionGroupList) => {
    if (!selectedOptionGroupList.length) {
      console.log("Resetting to first level ", selectedOptionGroupList, " selectedOptionGroupList ");
      resetCompStates();
      return;
    }

    //set loader
    setLoader(true);
    setSelectedOptionGroup(selectedOptionGroupList);
    axios({
      method: "POST",
      url: `${getSdkURL()}api/scope/getAvailableOptionGroupsOrSku`,
      data: {
        productID: product.productID,
        selectedOptions: selectedOptionGroupList,
      },
    }).then((response) => {
      setLoader(false);
      if (response?.status === 200 && response?.data?.failureActions.length === 0) {
        if (response?.data?.data?.filteredOptionGroup?.optionGroupCode !== "") {
          setOptionsToMap(response.data.data.filteredOptionGroup.options);
          const exitingFilterGroup = product.optionGroups.find(
            (item) => item.optionGroupCode === response.data.data.filteredOptionGroup.optionGroupCode,
          );
          setCurrentOptionGroup({
            optionGroupCode: exitingFilterGroup.optionGroupCode,
            optionGroupName: exitingFilterGroup.optionGroupName,
          });
        } else {
          if (Object.keys(response?.data?.data?.filteredSku).length) {
            handleSkuAdd(response?.data?.data?.filteredSku);
          } else {
            console.log(" no filter group or sku returned from API, something went wrong. Resetting to first level");
          }

          resetCompStates();
        }
      } else {
        toast.error(getErrorMessage(response?.data?.failureActions));
      }
    });
  };

  const handleOptionAdd = (optionName, optionCode) => {
    const optionGroupExists =
      selectedOptionGroup.filter((item) => {
        return item.optionCode === optionCode && currentOptionGroup.optionGroupCode === item.optionGroupCode;
      }).length > 0;

    if (!optionGroupExists) {
      const selectedOptionGroupList = [
        ...selectedOptionGroup,
        {
          optionName: optionName,
          optionCode: optionCode,
          optionGroupCode: currentOptionGroup.optionGroupCode,
          optionGroupName: currentOptionGroup.optionGroupName,
        },
      ];
      getFilteredOptionGroups(selectedOptionGroupList);
    } else {
      console.log(" This option  is already selected, shouldn't happen.");
    }
  };

  const handleSkuAdd = (sku, skuConfiguration) => {
    //TODO : make sure all keys are available on sku, since this data comes from 2 different apis
    const transformedSkuRecord = {
      skuCode: sku.skuCode,
      skuID: sku.skuID,
      productName: sku.productName,
      price: sku.price,
      skuConfiguration,
    };

    if (product.modifiers?.length && !skuConfiguration) {
      setShowSkuModifiers(sku);
      return;
    }

    let toastMessage = "Items added successfully";

    //check if product is already added
    const productExists = selectedSkus.filter((item) => item.productName === transformedSkuRecord.productName);

    //create new variable to mantain record to be added
    let skuRecordToAdd = {};
    skuRecordToAdd = { productName: transformedSkuRecord.productName, modifiers: product.modifiers };
    if (!productExists.length) {
      //if product doesn't exists, we can append it directly
      skuRecordToAdd["skus"] = [{ ...transformedSkuRecord, quantity: 1 }];
      setSelectedSkus([...selectedSkus, skuRecordToAdd]);
    } else {
      //if product is already added, we'll check if sku is added
      const finalArrayToAdd = selectedSkus.map((product) => {
        if (product.productName === skuRecordToAdd["productName"]) {
          //check if sku is added
          const skuExists = product.skus.filter((item) => {
            if (product.modifiers?.length) {
              return (
                item["skuID"] === transformedSkuRecord.skuID &&
                isEqual(item.skuConfiguration, transformedSkuRecord.skuConfiguration)
              );
            }
            return item["skuID"] === transformedSkuRecord.skuID;
          });

          //if sku is not added, we can add it
          if (!skuExists.length) {
            product.skus.push({ ...transformedSkuRecord, quantity: 1 });
          } else {
            //if sku is already added, increase quantity by 1
            product.skus.map((item) => {
              if (item["skuID"] === transformedSkuRecord.skuID) {
                if (
                  !product.modifiers?.length ||
                  isEqual(item.skuConfiguration, transformedSkuRecord.skuConfiguration)
                ) {
                  item["quantity"] = item["quantity"] + 1;
                }
              }
              return item;
            });
            toastMessage = "This item is already added, quantity has been increased by 1.";
          }
        }
        return product;
      });
      setSelectedSkus(finalArrayToAdd);
    }

    toast.success(toastMessage);
  };

  const handleOptionRemove = () => {
    if (!selectedOptionGroup.length) {
      console.log("Action calling this method shouldn't be visible ");
      return;
    }
    getFilteredOptionGroups(selectedOptionGroup.slice(0, -1));
  };

  const OptionGroupList = () => {
    return (
      <div className="row mx-1 my-1">
        {hasOptionGroupsOnProduct &&
          optionsToMap.map(({ optionName, optionCode }) => {
            return (
              <button
                key={optionCode}
                className="btn btn-outline-primary trade-order-filter-btn mx-1"
                onClick={(e) => handleOptionAdd(optionName, optionCode)}
              >
                {optionName}
              </button>
            );
          })}
        {!hasOptionGroupsOnProduct && (
          <button
            className="btn btn-outline-primary trade-order-filter-btn mx-1"
            onClick={(e) => {
              const defaultSku = product.skus.find((sku) => sku.skuID === product.defaultSku_skuID) || product.skus[0];
              handleSkuAdd({
                skuCode: defaultSku.skuCode,
                skuID: defaultSku.skuID,
                productName: defaultSku.product_productName,
                price: Math.min(defaultSku.salePrice, defaultSku.listPrice),
              });
            }}
          >
            Add Item
          </button>
        )}
      </div>
    );
  };

  const OptionGroupListWithSlider = () => {
    const optionNamePrefix = selectedOptionGroup
      .map((option) => option.optionGroupName + " " + option.optionName)
      .join(", ");
    return (
      <div className="product-category-slick my-3">
        <button
          className="btn btn-sm btn-danger  mx-1"
          onClick={(e) => {
            handleOptionRemove();
          }}
        >
          Back
        </button>
        <Slider {...sliderSettings}>
          {optionsToMap.map(({ optionName, optionCode }) => {
            return (
              <div key={optionCode}>
                <button
                  className="btn btn-outline-primary btn-sqaure mx-2"
                  onClick={(e) => handleOptionAdd(optionName, optionCode)}
                >
                  {optionNamePrefix + " " + optionName}
                </button>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  };

  return (
    <LoaderWrapper loader={loader}>
      {!selectedOptionGroup.length && <OptionGroupList />}
      {!!selectedOptionGroup.length && <OptionGroupListWithSlider />}
      {showSkuModifiers ? (
        <ProductModifiersModal
          setShow={setShowSkuModifiers}
          sku={showSkuModifiers}
          product={product}
          addItem={(skuConfiguration) => {
            handleSkuAdd(showSkuModifiers, skuConfiguration);
            setShowSkuModifiers(false);
          }}
        />
      ) : null}
    </LoaderWrapper>
  );
};

export default ProductFilterGroupList;
