import { useTranslation } from "react-i18next";

import { FacetFilterModel } from "./FacetFilter.componentModel";
import { useElementContext } from "../../../../contexts";

export const FacetFilterView = ({
  componentData: { config, searchTerm, filter, facetKey, searchResults, updateCount, appliedFilters },
  setSearchTerm,
  onShowMoreClick,
  updateAttribute,
  onShowLessClick,
}: {
  componentData: FacetFilterModel;
  setSearchTerm: (value: string) => void;
  updateAttribute: (data: { name: string; filterName: string }) => void;
  onShowMoreClick: () => void;
  onShowLessClick?: () => void;
}) => {
  const { ProductListingModule } = useElementContext();
  const { t } = useTranslation();

  return (
    <div className="filter-list-container mt-4">
      <h5 className="fw-normal">{filter.name}</h5>

      {filter.showFacetSearch && (
        <div className={`input-group rounded-pill my-2 ${facetKey}-search `}>
          <input
            className="form-control remove-focus border-end-0"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            type="text"
            placeholder="Search"
          />
          <span className="btn btn-link">
            <i className="bi bi-search"></i>
          </span>
        </div>
      )}
      <div
        className={`filter-items-list pt-2 ps-2 pe-3 listingFilter ${facetKey}-list`}
        style={{ maxHeight: "12rem", overflowY: "auto", overflowX: "hidden" }}
      >
        {searchResults &&
          searchResults.map((facet: any, index: number) => {
            if (index + 1 > updateCount) return null;
            const isSelected = appliedFilters.includes(facet.slug);
            return (
              <ProductListingModule.CoreControl.AttributeList
                showCounts={true}
                isSelected={isSelected}
                filterName={filter.name}
                facet={facet}
                key={facet.id || facet.name}
                facetKey={facetKey}
                updateAttribute={updateAttribute}
              />
            );
          })}

        {searchResults.length > updateCount && (
          <button className="link-button small mb-2" onClick={onShowMoreClick}>
            {t("frontend.product.select_more")}
          </button>
        )}
        {onShowLessClick && (
          <button className="link-button small mb-2" onClick={onShowLessClick}>
            {t("frontend.product.select_less")}
          </button>
        )}
      </div>
    </div>
  );
};
