export interface AddProductToQuoteModalProps {
  skuID?: string;

  skuIDs?: string;
  quantities?: string;

  setQuoteModal: (isOpen: boolean) => void;
  quantity?: number;
  skuConfiguration?: any;
}

export class AddProductToQuoteModalModel {
  skuID?: string;
  quantity: number = 1;
  isLoading: boolean = false;
  isLoadingForExisitingQuote: boolean = false;
  existingQuotes: { value: string; key: string }[] = [];
  existingQuoteVal: string = "";
  quoteName: string = "";

  skuIDs?: string;
  quantities?: string;

  isAuthenticated: boolean = false;

  constructor({
    props,
    isAuthenticated,
    isLoading,
    isLoadingForExisitingQuote,
    existingQuoteVal,
    existingQuotes,
    quoteName,
  }: {
    props: AddProductToQuoteModalProps;
    isAuthenticated: boolean;
    isLoading: boolean;
    isLoadingForExisitingQuote: boolean;
    existingQuotes: { value: string; key: string }[];
    existingQuoteVal: string;
    quoteName: string;
  }) {
    this.skuID = props.skuID;
    this.quantity = props.quantity || 1;
    this.isAuthenticated = isAuthenticated;
    this.existingQuoteVal = existingQuoteVal;
    this.existingQuotes = existingQuotes;
    this.isLoading = isLoading;
    this.isLoadingForExisitingQuote = isLoadingForExisitingQuote;
    this.quoteName = quoteName;
    this.skuIDs = props.skuIDs;
    this.quantities = props.quantities;
  }
}
