import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { useFormatCurrency } from "../../hooks";
import { isAuthenticated } from "../../utils";
import { getBrandRoute, getProductRoute } from "../../selectors";
import { addToCart } from "../../actions";
import { useElementContext } from "../../contexts";

const ProductRow = ({ product, config, quantity, onButtonClick, showInput = false }) => {
  const {
    ProductCardModule,
    CommonModule: { ProductModal, Button },
  } = useElementContext();
  const isCartFetching = useSelector((state) => state.cart.isFetching);
  const [formatCurrency] = useFormatCurrency({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setModal] = useState(false);
  const authenticationRequiredForAddToCart = !!product?.settings?.skuRequireLoginToAddToCart
    ? !isAuthenticated()
    : false;
  const calculatePriceOrRange = (product) => {
    if (!product.skus || product.skus?.length <= 1) return formatCurrency(product.salePrice);
    const prices = product.skus?.map((sku) => sku.salePrice).sort();
    const minPrice = prices.at(0);
    const maxPrice = prices.at(-1);
    if (minPrice === maxPrice) return formatCurrency(minPrice);
    return `${formatCurrency(minPrice)} - ${formatCurrency(maxPrice)}`;
  };
  const defaultSku = product.skus.find((sku) => sku.skuID === product.defaultSku_skuID) || product.skus[0];
  return (
    <tr className="productRow">
      <td>
        <div style={{ maxHeight: 100, maxWidth: 150 }}>
          <ProductCardModule.CoreControl.ProductCardImage product={defaultSku} />
        </div>
      </td>
      <td>{product.productName}</td>

      <td>{calculatePriceOrRange(product)}</td>
      {showInput && (
        <td>
          {product?.skus?.length <= 1 && (
            <input
              type="number"
              className="form-control"
              value={quantity}
              disabled={isCartFetching}
              onChange={(e) => {
                e.preventDefault();
                onButtonClick(defaultSku, parseInt(e.target.value, 10));
              }}
            />
          )}
        </td>
      )}
      <td>
        {authenticationRequiredForAddToCart && product?.skus?.length <= 1 && (
          <div className="alert alert-warning fs-6" role="alert">
            {t("frontend.product.loginToAdd")}
            <br />
            <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
          </div>
        )}
        {!authenticationRequiredForAddToCart && product?.skus?.length <= 1 && (
          <Button
            disabled={isCartFetching}
            className="btn btn-primary btn-block my-3"
            label={t(config.buttonLabel)}
            onClick={(e) => {
              e.preventDefault();
              onButtonClick(defaultSku, quantity + 1);
            }}
          />
        )}
        {product?.skus?.length > 1 && (
          <>
            <Button
              disabled={isCartFetching}
              className="btn btn-primary btn-block my-3"
              label={"Configure Product"}
              onClick={(e) => {
                e.preventDefault();
                setModal(true);
              }}
            />
            {showModal && (
              <ProductModal
                product={product}
                setShow={setModal}
                addToCart={(skuID, itemCount) => {
                  setModal(false);
                  dispatch(addToCart(skuID, itemCount));
                }}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
};

const SkuRow = ({ config, product, quantity, onButtonClick, showInput = false }) => {
  const {
    ProductModule,
    ProductCardModule,
    CommonModule: { Button },
  } = useElementContext();
  const isCartFetching = useSelector((state) => state.cart.isFetching);
  const { t } = useTranslation();
  const brand = useSelector(getBrandRoute);
  const producturl = useSelector(getProductRoute);
  const productLink = `/${producturl}/${product.urlTitle}` + (product.skuID.length ? `?skuid=${product.skuID}` : "");
  const [isFetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const authenticationRequiredForAddToCart = !!product?.settings?.skuRequireLoginToAddToCart
    ? !isAuthenticated()
    : false;

  return (
    <tr className="SkuRow">
      <td className="product-image">
        <div style={{ maxHeight: 100, maxWidth: 150 }}>
          <ProductCardModule.CoreControl.ProductCardImage product={product} />
        </div>
      </td>
      <td className="product-sku">
        <Link
          to={`/${brand}/${product.product_brand_urlTitle}`}
          className="text-capitalize mb-3"
          style={{ fontSize: 12 }}
        >
          {product.product_brand_brandName}
        </Link>
        <h2>
          <Link to={productLink} className="product-name d-inline-block w-100">
            {product.skuName}
          </Link>
        </h2>
        {!product.skuCode && product.productCode && <div className="product-brand">{product.productCode}</div>}
        {product.skuCode && <div className="product-brand">{product.skuCode}</div>}
      </td>

      <td className="product-price">
        <ProductModule.CoreComponents.ProductPrice
          salePrice={product.salePrice}
          listPrice={product.listPrice}
          className="d-flex"
        />
      </td>
      {showInput && (
        <td className="product-quantity">
          <input
            type="number"
            className="form-control"
            value={quantity}
            disabled={isCartFetching}
            min={0}
            onChange={(e) => {
              e.preventDefault();
              onButtonClick(product, parseInt(e.target.value, 10));
            }}
          />
        </td>
      )}
      <td className="product-btn">
        {authenticationRequiredForAddToCart && (
          <div className="alert alert-warning fs-6" role="alert">
            {t("frontend.product.loginToAdd")}
            <br />
            <Link to="/my-account/login"> {t("frontend.account.login")} </Link>
          </div>
        )}
        {!authenticationRequiredForAddToCart && (
          <Button
            className="btn btn-primary btn-block my-3"
            label={t(config.buttonLabel)}
            disabled={isFetching}
            isLoading={isFetching}
            onClick={(e) => {
              e.preventDefault();
              setFetching(true);
              if (onButtonClick) {
                onButtonClick(product, quantity + 1);
                setFetching(false);
              } else {
                dispatch(addToCart(product.skuID, quantity)).then(() => {
                  setFetching(false);
                });
              }
            }}
          />
        )}
      </td>
    </tr>
  );
};

export { ProductRow, SkuRow };
