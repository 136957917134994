import { Dispatch, SetStateAction } from "react";
import { ProductModifier } from "../../../interface/Product";

export interface ProductModifiersProps {
  modifiers: ProductModifier[];
  skuConfiguration: { [key: string]: string };
  setSkuConfiguration: Dispatch<SetStateAction<ProductModifiersProps["skuConfiguration"]>>;
}

export const ProductModifiers = ({ modifiers, skuConfiguration, setSkuConfiguration }: ProductModifiersProps) => {
  return (
    <div className="d-flex">
      <div>
        {modifiers?.map((modifier) => (
          <div className="my-4" key={modifier.key}>
            <label className="h6 pb-1">{modifier.title}</label>
            <select
              className="custom-select rounded-pill"
              value={skuConfiguration[modifier.key]}
              onChange={(e) => {
                setSkuConfiguration((prevState) => ({
                  ...prevState,
                  [modifier.key]: e.target.value,
                }));
              }}
            >
              <option value="">-- Choose Configuration Setting --</option>
              {modifier.options.map((value) => (
                <option key={value.slug} value={value.slug}>
                  {value.title}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
    </div>
  );
};
